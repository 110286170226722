@tailwind components;
@tailwind utilities;

:root {
  --space: 2px;
}

.stripe-bg {
  background-size: auto auto;
  background-color: rgba(43, 136, 77, 1);
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(67, 179, 34, 1) 10px,
    rgba(67, 179, 34, 1) 20px
  );
}

.patient-box-container {
    transition: width 0.5s;
}

.room-box {
  border-radius: 20px;
  margin: 10px;
  padding: 5px;
  float: left;
  width: 300px;
  box-shadow: 1px 1px 3px gray;
}

.room-heading {
  text-indent: 15px;
  font-family: Arial, Helvetica, sans-serif;
  color: gray;
}

.patient-heading {
    display: block;
    width: 50%;
    text-indent: 5px;
    font-family: Arial, Helvetica, sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: hidden;
    color: #0F4046;
}

.rooms-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}

.algorithm-button {
  display: inline-block;
  width: 200px;
  border-style: solid;
  background: white;
  border-radius: 30px;
  height: 30px;
  color: #37b7c7;
  border-width: 1px;
  border-color: #37b7c7;
}

.button-div {
  text-align: center;
}

.no-bed-heading {
  text-indent: 15px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(255, 255, 255);
}

/* Content dummy for empty pages */
.content {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  margin-left: 250px;
}

/* Custom Sidebar styling */
.nav-logo {
  margin-left: 1.5rem;
  font-size: 2rem;
  height: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sidebar-nav {
    height: 100vh; 
    min-height: 100vh;
    max-height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    top:  0;
    z-index: 10;
    margin-top: -800vh;
}

.override {
  background: red;
}

.sidebar-link {

    display: flex;
    color: white;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 18px;
    border: 4px solid transparent;
}

.sidebar-link:hover {
  border-left: 4px solid #37b7c7;
  cursor: pointer;
}

.sidebar-label {
  margin-left: 16px;
}

.dropdown-link {
  background: none;
  height: 60px;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #37b7c7;
  font-size: 18px;
  border: 4px solid transparent;
}

.dropdown-link:hover {
  border-left: 4px solid #37b7c7;
  cursor: pointer;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

/* stylelize scroller */

.addPatientBox::-webkit-scrollbar {
  width: 10px;
}

.addPatientBox::-webkit-scrollbar {
  width: 10px;
}

.addPatientBox::-webkit-scrollbar-thumb {
  background: #00b4d89f;
  border-radius: 5px;
}

.addPatientBox::-webkit-scrollbar-thumb:hover {
  background: #00b5d8;
}

.addPatientBox::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ccc
}
