.datepicker {
    user-select: none;
}

.datepicker-arrow {
  margin-top: 25px;
}

.datepickerMonth {
    white-space: nowrap;
    margin-bottom: 10px;
    display: block;

}

.datepickerMonth.hidden {
  visibility: hidden;
}

.datepickerItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.datepickerDate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.datepickerDate:hover {
  background-color: #37b7c7;
}

.datepickerDay {
  font-weight: normal;
}

.datepickerDay.selected {
  color: #ffffff;
}

.datepickerDate:hover .datepickerDay {
  color: white;
}

.datepickerWeekday {
  font-weight: bold;
  color: #37b7c7;
}

.datepickerWeekday.selected {
  color: #ffffff;
}

.datepickerDate:hover .datepickerWeekday {
  color: white;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
